export const dataSectionsEs = [
  {
    sectionDescriptionId: "Nosotros",
    sectionDescriptionText: "La única marina en la zona Diamente de Acapulco",
    distribution: "normal",
    title: "Marina Cabo Marqués",
    content: [
      {
        tag: "p",
        content: "Está ubicada en la zona más exclusiva de Acapulco, ofrece resguardo contra huracanes y cuenta con todos los servicios con el más moderno equipamiento."
      },
      {
        tag: "p",
        content: "La Marina Cabo Marqués es parte del resort más exclusivo de la región, junto con el hotel número uno de Acapulco <strong>Banyan Tree Cabo Marqués</strong> y el conjunto residencial más seguro y privado, <strong>Los Riscos.</strong>"
      }
    ],
    images: [
      "/images/fotos-marina/2-nosotros/nosotros-1.jpg",
      "/images/fotos-marina/2-nosotros/nosotros-2.jpg",
      "/images/fotos-marina/2-nosotros/nosotros-3.jpg"
    ]
  },
  {
    sectionDescriptionId: "Beneficios",
    sectionDescriptionText: "Disfrute de los beneficios para socios de la marina",
    distribution: "inverse",
    title: "Beneficios",
    content: [
      {
        tag: "ul",
        content: [
          "La mejor ubicación: Zona Diamante de Acapulco",
          "La marina más moderna del Pacífico mexicano",
          "Recibimos embarcaciones de hasta 150 pies de eslora",
          "Su topografía lo protege de condiciones meterológícas adversas",
          "El atenuador de oleaje flotante y las tablestacas rompeolas aseguran el confort de su embarcación",
          "Los servicios se ofrecen las 24 horas los 365 dias del año"
        ]
      }
    ],
    images: [
      "/images/fotos-marina/3-beneficios/beneficios-1.jpg",
      "/images/fotos-marina/3-beneficios/beneficios-2.jpg",
      "/images/fotos-marina/3-beneficios/beneficios-3.jpg"
    ]
  },
  {
    sectionDescriptionId: "Nautica",
    sectionDescriptionText: "La mejor experiencia náutica en Acapulco",
    distribution: "normal",
    title: "Marina náutica",
    content: [
      {
        tag: "ul",
        content: [
          "118 posiciones de amarre (slips) en 6 muelles flotantes",
          "Slips para embarcaciones desde 31 hasta 150 pies de eslora",
          "4 canales náuticos con más de 25 metros de ancho, boyas náuticas y señalización nocturna",
          "Gran calado (hasta 15 metros de profundidad)",
          "Sistema de comunicación con banda ancha náutica",
          "Torretas de servicios con medición de agua y electricidad acorde a la demanda de su embarcación",
          "Acceso vehicular a pie de muelle",
          "Muelles flotantes antiderrapantes para tránsito peatonal sin calzado",
          "Iluminación nocturna",
          "Carritos eléctricos para traslado al interior de la marina",
          "Circuito cerrado de televisión",
          "Bodegas privadas con vigilancia contínua",
          "Exceptional Card le permite el uso de Banyan Tree Cabo Marqués como casa club",
        ]
      }
    ],
    images: [
      "/images/fotos-marina/4-marina-nautica/marina-nautica-1.jpg",
      "/images/fotos-marina/4-marina-nautica/marina-nautica-2.jpg",
      "/images/fotos-marina/4-marina-nautica/marina-nautica-3.jpg"
    ]
  },
  {
    sectionDescriptionId: "Seca",
    sectionDescriptionText: "Un servicio integral para su embarcación",
    distribution: "inverse",
    title: "Marina seca",
    content: [
      {
        tag: "ul",
        content: [
            "200 posiciones para embarcaciones y equipos náuticos",
            "Eslora máxima: 38 pies",
            "Manga máxima: 10 pies",
            "Equipo para traslado y botado de embarcaciones",
            "Rampa privada para botado",
            "Malla sombra con protección solar para su embarcación",
            "Administración de su embarcación",
            "Agua potable y energía eléctrica",
            "Circuito cerrado de televisión",
            "Iluminación nocturna",
            "Bodegas privadas con vigilancia nocturna"
        ]
      }
    ],
    images: [
      "/images/fotos-marina/5-marina-seca/marina-seca-1.jpg",
      "/images/fotos-marina/5-marina-seca/marina-seca-2.jpg",
      "/images/fotos-marina/5-marina-seca/marina-seca-3.jpg"
    ]
  },
  {
    sectionDescriptionId: "Otros",
    sectionDescriptionText: "Servicios complementarios a su disposición",
    distribution: "normal",
    title: "Otros servicios",
    content: [
      {
        tag: "ul",
        content: [
          "Renta de bodegas",
          "Renta de yates",
          "Uso de instalaciones del prestigiado Banyan Tree Cabo Marqués"
        ]
      }
    ],
    images: [
      "/images/fotos-marina/6-otros-servicios/otros-servicios-1.jpg",
      "/images/fotos-marina/6-otros-servicios/otros-servicios-2.jpg",
      "/images/fotos-marina/6-otros-servicios/otros-servicios-3.jpg"
    ]
  },
  {
    sectionDescriptionId: "Membresias",
    sectionDescriptionText: "Conviértase en socio de la Marina Cabo Marqués",
    distribution: "inverse",
    title: "Membresías",
    content: [
      {
        tag: "h3",
        content: "La membresía es ideal para usted que desea utilizar la marina de manera permanente."
      },
      {
        tag: "p",
        content: "Le permite el uso de todas las instalaciones de la Marina Cabo Marqués, slip para su embarcación y equipos náuticos en la marina seca y náutica de manera permanente; embarque, desembarque y estadía para usted y sus invitados; uso de club de playa y <strong>Banyan Tree Cabo Marqués</strong>"
      },
      {
        tag: "h3",
        content: "Tipos de membresías:"
      },
      {
        tag: "ul",
        content: [
          "1 años",
          "5 años",
          "10 años",
          "20 años (Exclusiva para propietarios de Banyan Tree Residences Cabo Marqués y Los Riscos)"
        ]
      },
      {
        tag: "contactButton",
        content: {
          to: "Contacto",
          text: "Reservar"
        }
      },
    ],
    images: [
      "/images/fotos-marina/7-membresias/membresias-1.jpg",
      "/images/fotos-marina/7-membresias/membresias-2.jpg",
      "/images/fotos-marina/7-membresias/membresias-3.jpg"
    ]
  },
  {
    sectionDescriptionId: "Certificado-Nautica",
    sectionDescriptionText: "Esquemas flexibles para disfrutar de la marina",
    distribution: "normal",
    title: "Certificado marina náutica",
    content: [
      {
        tag: "h3",
        content: "Ideal para utilizar la marina náutica de manera intermitente."
      },
      {
        tag: "p",
        content: "Le permite el uso de embarque, desembarque y estadía de su embarcación por los días que requiera, acceso para usted y sus invitados por Cabo Marqués y uso de estacionamiento privado."
      },
      {
        tag: "h3",
        content: "Tipos de membresías:"
      },
      {
        tag: "ul",
        content: [
          "6 Meses",
          "1 Año"
        ]
      },
      {
        tag: "p",
        content: "Cada certificado le ofrece gratuitamente un número de operaciones y estadías durante su vigencia dependiendo de la eslora de su embarcación."
      },
      {
        tag: "contactButton",
        content: {
          to: "Contacto",
          text: "Reservar"
        }
      },
    ],
    images: [
      "/images/fotos-marina/8-certificado-marina-nautica/certificado-marina-nautica-1.jpg",
      "/images/fotos-marina/8-certificado-marina-nautica/certificado-marina-nautica-2.jpg",
      "/images/fotos-marina/8-certificado-marina-nautica/certificado-marina-nautica-3.jpg"
    ]
  },
  {
    sectionDescriptionId: "Certificado-Seca",
    sectionDescriptionText: "Opciones de pago por uso",
    distribution: "inverse",
    title: "Certificado marina seca",
    content: [
      {
        tag: "h3",
        content: "Ideal para utilizar la marina seca de manera intermitente o solamente botar su embarcación."
      },
      {
        tag: "p",
        content: "El certificado le permite el uso arrastre de embarcación, rampa de botado, embarque, desembarque y estadía de su embarcación en la marina náutica o seca, por los días que requiera, acceso para usted y sus invitados por Cabo Marqués y uso de estacionamiento privado."
      },
      {
        tag: "p",
        content: "<strong>Tipos de Certificado:</strong>"
      },
      {
        tag: "ul",
        content: [
          "Certificado Marina Seca",
          "Certificado Marina Seca + Cuotas de espacio"
        ]
      },
      {
        tag: "p",
        content: "El certificado de marina seca es anual y usted solo paga por las operaciones que realice."
      },
      {
        tag: "p",
        content: "El certificado de marina seca + cuotas de espacio es anual y  cuenta con un número de operaciones incluidas a  tarifas preferenciales."
      },
      {
        tag: "contactButton",
        content: {
          to: "Contacto",
          text: "Reservar"
        }
      },
    ],
    images: [
      "/images/fotos-marina/9-certificado-marina-seca/certificado-marina-seca-1.jpg",
      "/images/fotos-marina/9-certificado-marina-seca/certificado-marina-seca-2.jpg",
      "/images/fotos-marina/9-certificado-marina-seca/certificado-marina-seca-3.jpg"
    ]
  },
  {
    sectionDescriptionId: "Embarcaciones-Transito",
    sectionDescriptionText: "La mejor marina para su estancia en Acapulco",
    distribution: "normal",
    title: "Embarcaciones en tránsito",
    content: [
      {
        tag: "h3",
        content: "Disfrute y descanse en el destino más exclusivo de su travesía náutica."
      },
      {
        tag: "p",
        content: "Aproveche al máximo del mejor servicio náutico en la Marina Cabo Marqués, el mejor Hotel y Spa: Banyan Tree Cabo Marqués."
      },
      {
        tag: "ul",
        content: [
          "Capacidad hasta 150 pies de eslora",
          "Energía eléctrica y agua potable a pie de muelle",
          "Seguridad y vigilancia las 24 horas al día",
          "Puerto de resguardo",
          "Descuentos en hospedaje, alimentos y bebidas en Banyan TreeCabo Marqués"
        ]
      },
      {
        tag: "contactButton",
        content: {
          to: "Contacto",
          text: "Reservar"
        }
      },
    ],
    images: [
      "/images/fotos-marina/10-embarcaciones-en-transito/embarcaciones-en-transito-1.jpg",
      "/images/fotos-marina/10-embarcaciones-en-transito/embarcaciones-en-transito-2.jpg",
      "/images/fotos-marina/10-embarcaciones-en-transito/embarcaciones-en-transito-3.jpg"
    ]
  },
  {
    sectionDescriptionId: "Certificado-NEB",
    sectionDescriptionText: "Viva una experiencia inolvidable en Cabo Marqués",
    distribution: "inverse",
    title: "Certificado NE&B",
    content: [
      {
        tag: "h3",
        content: "Un momento romántico y una experiencia náutica inigualable en Banyan Tree Cabo Marqués."
      },
      {
        tag: "p",
        content: "Con el certificado Navigate, Enjoy and Buy (NE&B), disfrute la relajación y armonía en Banyan Tree SPA, comida Thai contemporánea y los atardeceres más espectaculares de Acapulco con acceso náutico a la Marina Cabo Marqués."
      },
      {
        tag: "contactButton",
        content: {
          to: "Contacto",
          text: "Me interesa"
        }
      },
    ],
    images: [
      "/images/fotos-marina/11-certificado-neyb/certificado-ne&b-1.jpg",
      "/images/fotos-marina/11-certificado-neyb/certificado-ne&b-2.jpg",
      "/images/fotos-marina/11-certificado-neyb/certificado-ne&b-3.jpg"
    ]
  },
  {
    sectionDescriptionId: "Tarifas",
    sectionDescriptionText: "Tarifas",
    distribution: "normal",
    title: "Tarifas Marina Cabo Marqués",
    content: [
      {
        tag: "h3",
        content: "Consulte el costo de los servicios."
      },
      {
        tag: "p",
        content: "Ponemos a su disposición un listado de servicios y sus respectivos costos."
      },
      {
        tag: "h3",
        content: "Pregunte a nuestros asesores por las ofertas especiales."
      },
      {
        tag: "contactButton",
        content: {
          to: "Contacto",
          text: "Contacto"
        }
      },
    ],
    images: [
      "/images/fotos-marina/12-tarifas/tarifas-1.jpg",
      "/images/fotos-marina/12-tarifas/tarifas-2.jpg",
      "/images/fotos-marina/12-tarifas/tarifas-3.jpg"
    ]
  },
]